import { useEffect, useState, useCallback, } from "react"
import { apiUrlPrefix } from "../authConfig";
import useHeader from "./useHeader";
import axios from "axios";
import { useSnackbar } from "notistack";
import { debounce } from "lodash";

export const useUserLayouts = (application, useShared = false) => {
  const headers = useHeader();
  const { enqueueSnackbar } = useSnackbar();
  const [layouts, setLayouts] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchLayouts();
  }, [headers]);

  async function fetchLayouts() {
    setLoading(true);

    let proc = 'UI_UserLayoutFetch_v2'
    if (useShared) {
      proc = 'UI_UserSharedLayoutFetch'
    }

    let loadUri = `${apiUrlPrefix}/CrystalBall/Store/Shelf?name=PowerStationMetaData.${proc}&parm=${headers.userGuid ?? ''}&parm=${application}`;

    const options = {
      headers: headers,
      url: loadUri,
    }

    return axios(options).then(response => {
      const views = response?.data ?? [];
      setLayouts(views.map(viewData => {
        const view = JSON.parse(viewData.json ?? '{}');
        return {
          ...view,
          label: viewData.template,
          id: viewData.userLayoutID,
          userName: viewData.userName,
        }
      }));
    }).catch(err => {
      enqueueSnackbar(`Error getting view data in for ${application}. ${err}`, { variant: 'error', });
      setLayouts([]);
    }).finally(() => {
      setLoading(false);
    });
  }

  async function insertUpdateLayout(data, refresh = true) {
    const newView = {
      grid: application,
      json: {
        ...data,
      },
      template: data.label,
      id: data.id ?? '',
    }

    const saveUri = `${apiUrlPrefix}/CrystalBall/Store/Shelf/JSON/Pushfetch?name=PowerStationMetaData.UI_UserLayoutInsertUpdate_v2&parm=${headers.userGuid}&parm=${newView.grid}&parm=${encodeURIComponent(newView.template)}&parm=${newView.id}`;

    const options = {
      method: 'POST',
      headers: headers,
      data: newView.json,
      url: saveUri,
    }

    return axios(options).then(response => {
      refresh && fetchLayouts();
      return response;
    }).catch(err => { enqueueSnackbar(`Error saving ${application} layout data.  ${err.response?.data ?? err.message}`, { variant: 'error', }) });
  }

  async function deleteLayout(layoutId) {
    return handleDelete(layoutId, layouts)
  }

  const handleDelete = useCallback(debounce(async (key, layouts) => {
    const deleteUri = `${apiUrlPrefix}/CrystalBall/Store/Shelf?name=PowerStationMetaData.UI_UserLayoutDelete_v2&parm=${headers.userGuid}&parm=${application}&parm=${key}`;

    const options = {
      headers: headers,
      url: deleteUri,
    }

    return axios(options).then(response => {
      const newLayouts = layouts.filter(layout => layout.id !== key);
      setLayouts(newLayouts);
    }).catch(err => { enqueueSnackbar(`Error deleting layout(s).  ${err}`, { variant: 'error', }) });
  }, 2000, { leading: true, trailing: false, }), []);

  return {
    layouts,
    fetchLayouts,
    deleteLayout,
    insertUpdateLayout,
    setLayouts,
    loading,
  }
}