import { useEffect, useState, createContext, useContext, useCallback, } from "react";
import { useApi } from "./useApi";

export const UserInfoContext = createContext();

export function UserInfoProvider({ children }) {
    const [userInfo, setUserInfo] = useState();
    const { apiUrlPrefix, headers, get } = useApi();

    useEffect(() => {
        fetchUserInfo();
    }, [headers]);

    const fetchUserInfo = useCallback(async () => {
        if (headers.userGuid) {
            const url = `${apiUrlPrefix}/CrystalBall/Store/Shelf?name=PowerStationMetaData.UI_UserInfoFetch&parm=${headers.userGuid}`;
            return get(url).then((response) => {
                setUserInfo(response.data[0]);
            });
        }
    }, [headers]);

    return (
        <UserInfoContext.Provider value={userInfo}>
            {children}
        </UserInfoContext.Provider>
    )
}

export const useUserInfo = () => useContext(UserInfoContext);