import { Dashboard } from "../../DealRizz/useDashboard";
import AddNewOrUpdateDialog from "./AddNewOrUpdateDialog";
import CorrelationsView from "./CorrelationsView";

export const CorrelationsDashboard = () => {
    const dashboardKey = 'correlations';
    const useShared = true;

    return (
        <Dashboard
            dashboardKey={dashboardKey}
            useShared={useShared}
            ViewComponent={CorrelationsView}
            AddNewOrUpdateDialog={AddNewOrUpdateDialog}
        />
    );
}