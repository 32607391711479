import { defaultColumnDef, defaultGridOptions } from "../../../AgGrid/defaultGridProps";
import { DateTimeCellEditor } from "../../../AgGrid/DateTimeCellEditor";
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css'
import '../../../../styles/gridStyleOverrides.css'
import { useColumnTypes } from "../../../AgGrid/useColumnTypes";
import { useMemo, useRef } from "react";
import dayjs from "dayjs";

export const ProfileGrid = ({ data, refreshChart }) => {
    const gridRef = useRef();
    const { columnTypes } = useColumnTypes();
    const formatString = 'MM/DD/YYYY HH:mm';

    const colDefs = useMemo(() => [
        {
            checkboxSelection: true,
            editable: true,
            headerName: 'Start',
            cellEditorPopup: true,
            cellEditor: 'dateEditor',
            type: 'dateColumn',
            cellEditorParams: {
                timeFormat: 'HH',
            },
            valueGetter: params => {
                return params.data.startDateTime;
            },
            valueSetter: params => {
                params.data.startDateTime = dayjs(params.newValue).format(formatString);
                return true;
            },
        },
        {
            headerName: 'Stop',
            editable: true,
            cellEditorPopup: true,
            cellEditor: 'dateEditor',
            type: 'dateColumn',
            cellEditorParams: {
                timeFormat: 'HH',
            },
            valueGetter: params => {
                return params.data.endDateTime;
            },
            valueSetter: params => {
                params.data.endDateTime = dayjs(params.newValue).format(formatString);
                return true;
            },
        },
        {
            field: 'capacityRequested',
            headerName: 'MW',
            editable: true,
        },
        {
            field: 'price',
            headerName: 'Price',
            //...priceColParams,
            editable: true,
        },
    ], [])

    const defaultColDef = useMemo(() => ({
        ...defaultColumnDef,
        editable: false,
        flex: 1,
    }), []);

    const gridOptions = {
        rowClassRules: {
            "row-missing-price": params => params.data.price === '',
        },
    };

    return (
        <AgGridReact
            {...defaultGridOptions}
            ref={gridRef}
            rowData={data}
            columnDefs={colDefs}
            columnTypes={columnTypes}
            onCellValueChanged={refreshChart}
            overlayNoRowsTemplate={'<span>Add blocks to view and edit profile information here.</span>'}
            rowSelection='multiple'
            gridOptions={gridOptions}
            defaultColDef={defaultColDef}
            components={{
                dateEditor: DateTimeCellEditor,
            }}
        />
    );
}