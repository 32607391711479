import { useState } from 'react';
import BarChartIcon from '@mui/icons-material/BarChart';
import TaskIcon from '@mui/icons-material/Task';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import AttachEmailIcon from '@mui/icons-material/AttachEmail';
import LiveHelpIcon from '@mui/icons-material/LiveHelp';
import styled from '@mui/system/styled';
import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Tooltip from '@mui/material/Tooltip';
import GroupGuard from '../Guards/GroupGuard.js';
import { userGroups } from '../../authConfig.js';
import StorageIcon from '@mui/icons-material/Storage';
import CallSplitIcon from '@mui/icons-material/CallSplit';
import ChatIcon from '@mui/icons-material/Chat';
import PsychologyIcon from '@mui/icons-material/Psychology';

const drawerWidth = 240;

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9),
        },
      }),
    },
  }),
);

export default ({ handleSelection, selected }) => {
  const [open, setOpen] = useState(false);

  return (
    <Drawer variant="permanent" open={open} onMouseEnter={() => setOpen(true)} onMouseLeave={() => setOpen(false)}>
      <List>
        <GroupGuard groups={[userGroups.helpcomposer]}>
          <Tooltip title="Compose the help file for this tenant." arrow placement="right">
            <ListItemButton
              selected={selected === 'helpcomposer'}
              onClick={() => handleSelection('helpcomposer')}>
              <ListItemIcon>
                <QuestionMarkIcon />
              </ListItemIcon>
              <ListItemText primary="Help Composer" />
            </ListItemButton>
          </Tooltip>
        </GroupGuard>
        <GroupGuard groups={[userGroups.requirements]}>
          <Tooltip title="View requirement documents for this tenant." arrow placement="right">
            <ListItemButton
              selected={selected === 'requirements'}
              onClick={() => handleSelection('requirements')}>
              <ListItemIcon>
                <TaskIcon />
              </ListItemIcon>
              <ListItemText primary="Requirements" />
            </ListItemButton>
          </Tooltip>
        </GroupGuard>
        <GroupGuard groups={[userGroups.support]}>
          <Tooltip title="Manage issue log." arrow placement="right">
            <ListItemButton
              selected={selected === 'support'}
              onClick={() => handleSelection('support')}>
              <ListItemIcon>
                <LiveHelpIcon />
              </ListItemIcon>
              <ListItemText primary="Support" />
            </ListItemButton>
          </Tooltip>
        </GroupGuard>
        <GroupGuard groups={[userGroups.admins]}>
          <ListItemButton
            selected={selected === 'dataanalysis'}
            onClick={() => handleSelection('dataanalysis')}>
            <ListItemIcon>
              <BarChartIcon />
            </ListItemIcon>
            <ListItemText primary="Data Analysis" />
          </ListItemButton>
        </GroupGuard>
        <GroupGuard groups={[userGroups.admins]}>
          <Tooltip title="View API Audit log." arrow placement="right">
            <ListItemButton
              selected={selected === 'apiaudit'}
              onClick={() => handleSelection('apiaudit')}>
              <ListItemIcon>
                <QueryStatsIcon />
              </ListItemIcon>
              <ListItemText primary="API Audit" />
            </ListItemButton>
          </Tooltip>
        </GroupGuard>
        <GroupGuard groups={[userGroups.admins]}>
          <Tooltip title="Execute CRUD operations on db tables." arrow placement="right">
            <ListItemButton
              selected={selected === 'dbutility'}
              onClick={() => handleSelection('dbutility')}>
              <ListItemIcon>
                <StorageIcon />
              </ListItemIcon>
              <ListItemText primary="DB Utility" />
            </ListItemButton>
          </Tooltip>
        </GroupGuard>
        <GroupGuard groups={[userGroups.emailcomposer]}>
          <Tooltip title="Compose an email to be sent out from the PSP." arrow placement="right">
            <ListItemButton
              selected={selected === 'emailcomposer'}
              onClick={() => handleSelection('emailcomposer')}>
              <ListItemIcon>
                <AttachEmailIcon />
              </ListItemIcon>
              <ListItemText primary="Email Composer" />
            </ListItemButton>
          </Tooltip>
        </GroupGuard>
        <GroupGuard groups={[userGroups.admins]}>
          <Tooltip title="Manage Azure Logic App Workflows." arrow placement="right">
            <ListItemButton
              selected={selected === 'workflows'}
              onClick={() => handleSelection('workflows')}>
              <ListItemIcon>
                <CallSplitIcon />
              </ListItemIcon>
              <ListItemText primary="Workflows" />
            </ListItemButton>
          </Tooltip>
        </GroupGuard>
        <GroupGuard groups={[userGroups.admins]}>
          <Tooltip title="Manage chatbot training and context." arrow placement="right">
            <ListItemButton
              selected={selected === 'chatbot'}
              onClick={() => handleSelection('chatbot')}>
              <ListItemIcon>
                <ChatIcon />
              </ListItemIcon>
              <ListItemText primary="Chatbot" />
            </ListItemButton>
          </Tooltip>
        </GroupGuard>
        <GroupGuard groups={[userGroups.admins]}>
          <Tooltip title="AI Support" arrow placement="right">
            <ListItemButton
              selected={selected === 'aiSupport'}
              onClick={() => handleSelection('aiSupport')}>
              <ListItemIcon>
                <PsychologyIcon />
              </ListItemIcon>
              <ListItemText primary="AI Support" />
            </ListItemButton>
          </Tooltip>
        </GroupGuard>
        {/*<GroupGuard groups={[]}>
          <Tooltip title="Place to develop and test components." arrow placement="right">
            <ListItemButton
              selected={selected === 'sandbox'}
              onClick={() => handleSelection('sandbox')}>
              <ListItemIcon>
                <CodeIcon />
              </ListItemIcon> 
              <ListItemText primary="Sandbox" />
            </ListItemButton>
          </Tooltip>
        </GroupGuard>*/}
        <Divider />
      </List>
    </Drawer>
  );
}