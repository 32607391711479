import { Divider, Box, Typography, Paper, ButtonBase, Tooltip, IconButton, Collapse, Stack, } from "@mui/material";
import { styled } from "@mui/styles";
import { useState, Children, cloneElement } from "react";
import Refresh from "@mui/icons-material/Refresh";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

export const Ribbon = ({ children, }) => {
    const [expanded, setExpanded] = useState(true);

    return (
        <RibbonContainer elevation={4}>
            {children.map(({ title, content, visible = true, }, index) => (
                <StyledCollapse key={title} in={visible} orientation="horizontal">
                    <RibbonGroup title={title} expanded={expanded}>{content}</RibbonGroup>
                    {index < children.length - 1 && <Divider orientation="vertical" variant='middle' flexItem />}
                </StyledCollapse>
            ))}
            <Tooltip title={expanded ? 'Minimize ribbon' : 'Expand ribbon'}>
                <IconButton onClick={() => setExpanded(!expanded)} sx={{ borderRadius: '2px', position: 'absolute', bottom: '2px', right: '2px', padding: 0.5 }}>
                    {expanded ? <KeyboardArrowUpIcon fontSize='small' /> : <KeyboardArrowDownIcon fontSize='small' />}
                </IconButton>
            </Tooltip>
        </RibbonContainer>
    );
}

const RibbonContainer = styled(Paper)(({ theme, }) => ({
    position: 'relative',
    display: 'flex',
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
    borderRadius: '5px',
    width: '100%',
}));

const RibbonGroup = ({ title, expanded, children }) => {
    const childrenWithProps = Children.map(children, child =>
        cloneElement(child, { expanded })
    );

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                alignItems: 'center',
                height: '100%',
                px: 1,
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: expanded ? 'start' : 'center',
                    alignItems: 'center',
                    flexGrow: 1,
                }}
            >
                {childrenWithProps}
            </Box>
            {expanded && <Typography variant="caption" align="center">
                {title}
            </Typography>}
        </Box>
    );
};

export const RibbonButton = ({ label, icon, expanded, ...buttonProps }) => {
    return (
        <StyledButtonBase
            {...buttonProps}
            sx={{
                color: !!buttonProps.disabled ? 'grey.500' : 'primary.main',
                transition: 'background-color 0.3s',
                '&:hover': {
                    backgroundColor: 'action.hover',
                },
                ...buttonProps.sx,
                maxWidth: '100px',
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    height: '80%',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                {icon}
            </Box>
            {expanded && <Typography variant="caption" sx={{ mt: 1 }} lineHeight='1.2em'>
                {label}
            </Typography>}
        </StyledButtonBase>
    );
};

export const RibbonGroupContainer = (props) => {
    const { children, sx, ...containerProps } = props;
    return (
        <Stack
            direction='row'
            spacing={2}
            sx={{
                display: 'flex',
                alignItems: 'center',
                pt: 2,
                pb: 1,
                ...sx,
            }}
            {...containerProps}
        >
            {children}
        </Stack>
    );
}

const StyledButtonBase = styled(ButtonBase)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(1),
    borderRadius: '5px',
}));

const StyledCollapse = styled(Collapse)(({ theme }) => ({
    '& .MuiCollapse-wrapperInner': {
        display: 'flex',
    },
}));