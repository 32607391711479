import { useRef, useMemo, useImperativeHandle, forwardRef, useCallback, useState, useEffect } from "react"
import { AgGridReact } from "ag-grid-react"
import { LayoutToolPanel } from "../../ToolPanels/LayoutToolPanel"
import { PaletteToolPanel } from "../../ToolPanels/PalettePanel"
import { defaultColumnDef, defaultGridOptions, defaultStatusBar } from "../../AgGrid/defaultGridProps"
import { columnPanel, filterPanel, palettePanel } from "../../ToolPanels/DefaultToolPanels"
import { heatmapStyle, } from "../Utils"
import { useDashboardFetch } from "../useDashboardFetch"
import { useDashboardLayout } from "../useDashboardLayout"
import { useApi } from "../../useApi"
import dayjs from "dayjs"
import { diffData, jsonOrCommaSeparatedFormatter } from "../Utils"
import { useTheme } from "@mui/material"
import { useColorMode } from "../../../styles/ColorMode/useColorMode"
import { enqueueSnackbar } from "notistack"

export const PositionManagementGrid = forwardRef(({ context, date, timezone, setSelectedRow, getRowId, }, ref) => {
    const paletteRef = useRef({ showHeatmap: false });
    const theme = useTheme();
    const layoutStorageKey = `deal-rizz-position-management-grid-layout-${context.id}`;
    const { post, headers, apiUrlPrefix, enqueueSnackbar, } = useApi();

    const isDeal = (data) => !data?.scheduleID;

    // Define your custom aggregation function in the aggFuncs object
    const aggFuncs = useMemo(() => {
        return {
            'myNetPosition': params => {
                let sum = 0;
                params.values.forEach(value => sum += value);
                return sum;
            }
        };
    }, []);


    const handleRowValueChanged = useCallback(async (event) => {
        const updatedRow = event.data; // Get the updated row data
        let url = ''

        if (updatedRow.type === 'Schedule/Tag' || updatedRow.type === 'Schedule') {
            // URL for Schedule/Tag
            url = `${apiUrlPrefix}/CrystalBall/Store/Shelf/JSON/Push?name=dealrizz.UI_ModifySchedule`
                + `&parm=${headers.userGuid}`
                + `&parm=${updatedRow.scheduleID}`
                + `&parm=${updatedRow.dealID}`
                + `&parm=${updatedRow.flowDate}`
                + `&parm=${updatedRow.flowDateTimeZone}`;
        } else if (updatedRow.type === 'Deal' || updatedRow.type === 'Deal/Forecast') {
            // URL for Deal or Deal/Forecast
            url = `${apiUrlPrefix}/CrystalBall/Store/Shelf/JSON/Push?name=dealrizz.UI_ModifyDealMW`
                + `&parm=${headers.userGuid}`
                + `&parm=${updatedRow.dealID}`
                + `&parm=${updatedRow.flowDate}`
                + `&parm=${updatedRow.flowDateTimeZone}`;
        }


        try {
            const response = await fetch(url, {
                method: "POST",
                headers: {
                    ...headers, // Your API headers (use the existing headers from `useApi`)
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(event.data), // Send the updated row as JSON
            });

            // enqueueSnackbar(JSON.stringify(updatedRow));

            if (response.ok) {
                enqueueSnackbar("Position updated successfully", { variant: "success" });
            } else {
                enqueueSnackbar("Failed to update row data", { variant: "error" });
            }
        } catch (error) {
            enqueueSnackbar("Error while updating row data", { variant: "error" });
        }
    }, [enqueueSnackbar, headers]);


    const baseColDefs = useMemo(() => ([
        {
            headerName: 'Deal ID',
            //checkboxSelection: true,
            field: 'dealID',
            cellDataType: 'text',
            // rowGroup: true,
            initialHide: false,
        },
        // {
        //     headerName: 'Deal/Schedule',
        //     valueGetter: params => isDeal(params.data) ? `Deal` : `Schedule`,
        // },
        {
            field: 'type',
            headerName: 'Deal/Schedule/Tag',
        },
        {
            field: 'scheduleID',
            headerName: 'Schedule Id',
        },
        {
            field: 'tagIdx',
            headerName: 'TagID',
        },
        {
            field: 'tagCode',
            headerName: 'Tag Code',
        },
        {
            field: 'Deal_Token',
            headerName: 'Deal Name',
            initialHide: false,
            rowGroup: true,
        },
        {
            field: 'Status',
            headerName: 'Status',
            initialHide: true,
        },
        {
            field: 'startDateTime',
            headerName: 'Start Time',
            filter: 'agDateColumnFilter',
            filterParams: {
                comparator: function (filterValue, cellValue) {
                    if (dayjs(cellValue).isBefore(filterValue, 'day')) {
                        return -1;
                    } else if (dayjs(cellValue).isAfter(filterValue, 'day')) {
                        return 1;
                    } else {
                        return 0;
                    }
                },
            },
            valueFormatter: params => {
                const isValid = dayjs(params.value).isValid();
                return params.value && isValid ? dayjs(params.value).format('MM/DD/YYYY HH:mm') : '';
            }
        },
        {
            field: 'endDateTime',
            headerName: 'End Time',
            filter: 'agDateColumnFilter',
            filterParams: {
                comparator: function (filterValue, cellValue) {
                    if (dayjs(cellValue).isBefore(filterValue, 'day')) {
                        return -1;
                    } else if (dayjs(cellValue).isAfter(filterValue, 'day')) {
                        return 1;
                    } else {
                        return 0;
                    }
                },
            },
            valueFormatter: params => {
                const isValid = dayjs(params.value).isValid();
                return params.value && isValid ? dayjs(params.value).format('MM/DD/YYYY HH:mm') : '';
            }
        },
        // {
        //     field: 'Forecast',
        //     initialHide: true,
        // },
        {
            field: 'Source',
        },
        {
            field: 'Sink',
        },
        {
            field: 'por',
            headerName: 'POR',
        },
        {
            field: 'pod',
            headerName: 'POD',
        },
        {
            field: 'dealFlowPath',
            headerName: 'Deal Flow Path',
        },
        {
            field: 'scheduleFlowPath',
            headerName: 'Schedule Flow Path',
        },
        {
            field: 'tagFlowPath',
            headerName: 'Tag Flow Path',
        },
        {
            initialWidth: "150px",
            valueGetter: (params) => {
                return jsonOrCommaSeparatedFormatter(params.data?.Book);
            },
            headerName: 'Book',
        },
        {
            field: "Counterparty",
            initialWidth: "150px",
            valueFormatter: (params) => {
                return jsonOrCommaSeparatedFormatter(params.value);
            },
        },
        {
            field: "marketPath",
            initialWidth: "150px",
            headerName: 'Market Path'
        },
        {
            field: 'Transaction_Type',
            headerName: 'Transaction Type',
            initialHide: true,
        },
        {
            headerName: 'Transaction',
            field: 'Type_F_P',
            initialHide: true,
        },
        ...Array.from({ length: 26 }, (_, i) => ({
            headerName: `${i + 1}`,
            field: `${i + 1}`,
            // editable: params => !!params.data.scheduleID,  // Editable only if scheduleID exists
            editable: params => params.data.type === 'Deal' || params.data.type === 'Deal/Forecast' || params.data.type === 'Schedule/Tag' || params.data.type === 'Schedule',
            flex: 1,
            enableValue: true,    // Enable value aggregation for these columns
            aggFunc: 'sum',       // Set 'sum' as the default aggregation function
            allowedAggFuncs: ['sum', 'min', 'max', 'avg', 'count', 'first', 'last', 'myNetPosition'],
            enableRowGroup: false,
            minWidth: 55,
            chartDataType: 'series',
            type: 'numericColumn',
            cellDataType: 'number',
            cellStyle: params => heatmapStyle(params, paletteRef),
        })),
    ]), []);

    const onColorModeChanged = useCallback(({ mode, }) => {
        if (ref.current.api) {
            //redraw the group rows when the theme changes
            const groupNodes = [];
            ref.current.api.forEachNode(node => {
                if (node.group) {
                    groupNodes.push(node);
                }
            });
            ref.current.api.context.mode = mode;
            ref.current.api.redrawRows({ rowNodes: groupNodes, force: true });
        }
    }, []);

    useColorMode(onColorModeChanged);

    const rowClassRules = useMemo(() => ({
        "ag-group-row-cell": params => params.api.context.mode !== 'dark' && (!!params.node.group || params.node.data?.isDealRow),
        "ag-group-row-cell-dark": params => params.api.context.mode === 'dark' && (!!params.node.group || params.node.data?.isDealRow),
    }), []);

    const defaultColDef = useMemo(() => ({
        ...defaultColumnDef,
        editable: false,
        enableRowGroup: true,
        minWidth: 100,
        flex: 2,
    }), []);

    const { applyFilters, applyLayout, colDefs, layoutPanel, } = useDashboardLayout({
        gridRef: ref,
        layoutStorageKey,
        context,
        baseColDefs,
        defaultColDef,
    });

    const styledColDefs = useMemo(() => colDefs.map(colDef => ({
        ...colDef,
    })), [colDefs,]);

    const sideBar = useMemo(() => ({
        toolPanels: [
            columnPanel,
            filterPanel,
            layoutPanel,
            palettePanel(ref, paletteRef, 'deal-rizz-position-management-palette'),
        ]
    }), []);

    function onGridReady(params) {
        applyLayout();
        onColorModeChanged({ mode: theme.palette.mode });
    }

    const handleFirstDataRendered = useCallback(() => {
        applyFilters();
    }, []);

    const updateTotalRows = useCallback(({ api, }) => {
        const data = [];
        ref.current?.api.forEachLeafNode(node => {
            data.push(node.data);
        });
        const totalRow = generateTotalRowData(data);
        //api.setGridOption('pinnedBottomRowData', totalRows);
        api.setPinnedBottomRowData(totalRow);
    }, [styledColDefs]);

    const generateTotalRowData = useCallback((data) => {
        if (!data?.length) { return []; }

        //create a Map with the hour as key
        const dataMap = new Map(Array.from({ length: 26 }, (_, i) => i).map(i => [i + 1, 0]));

        //now for every deal, add the MW for each hour, and subtract the scheduled MW for each hour
        data.forEach(row => {
            if (isDeal) {
                dataMap.keys().forEach(i => {
                    dataMap.set(i, dataMap.get(i) + (row[i] ?? 0));
                });
            } else {
                dataMap.keys().forEach(i => {
                    dataMap.set(i, dataMap.get(i) - (row[i] ?? 0));
                });
            }
        });

        const totalRow = Array.from(dataMap.keys()).reduce((acc, i) => {
            acc[i] = dataMap.get(i);
            return acc;
        }, { dealID: 'Total:' });
        return [totalRow];
    }, [styledColDefs]);

    const handleSelectionChanged = useCallback(() => {
        const selected = ref.current?.api?.getSelectedRows();
        setSelectedRow(selected[0]);
    }, []);

    const statusBar = {
        statusPanels: [
            { statusPanel: 'agTotalRowCountComponent', align: 'left' },
            { statusPanel: 'agFilteredRowCountComponent' },
            { statusPanel: 'agSelectedRowCountComponent' },
            { statusPanel: 'agAggregationComponent' }  // This enables the aggregation functionality in the status bar
        ]
    };

    return (
        <AgGridReact
            {...defaultGridOptions}
            containerStyle={{ height: '100%', width: '100%' }}
            ref={ref}
            getRowId={getRowId}
            // onCellValueChanged={handleCellValueChanged}
            editType="fullRow"
            onRowValueChanged={handleRowValueChanged}
            stopEditingWhenCellsLoseFocus={true}
            rowClassRules={rowClassRules}
            // onFilterChanged={updateTotalRows}
            // onRowDataUpdated={updateTotalRows}
            onSelectionChanged={handleSelectionChanged}
            rowSelection='single'
            rowMultiSelectWithClick
            overlayNoRowsTemplate="No rows. Change the start date in the ribbon to a time with deals."
            enableCharts
            columnDefs={styledColDefs}
            onFirstDataRendered={handleFirstDataRendered}
            onGridReady={onGridReady}
            statusBar={defaultStatusBar}
            // statusBar={statusBar}
            sideBar={sideBar}
            groupDefaultExpanded={1}
            // groupDisplayType="groupRows"
            suppressAggFuncInHeader
            suppressLastEmptyLineOnPaste
            components={{
                layoutToolPanel: LayoutToolPanel,
                paletteToolPanel: PaletteToolPanel,
            }}
            aggFuncs={aggFuncs}  // Pass the custom aggregation functions here
            groupIncludeFooter={true}         // Include sum at the group level
            groupIncludeTotalFooter={true}    // Include total sum at the bottom
        />
    )
});