import { Button, Grid, TextField, InputAdornment, } from '@mui/material';
import FormDateTimePicker from "../../../FormControls/FormDateTimePicker";
import { LocalizationProvider, } from "@mui/x-date-pickers";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs';
import FormAutocomplete from '../../../FormControls/FormAutocomplete';
import { renderDayjsTimezones } from '../../../../utils/renderTimezones';
import { useFormContext } from 'react-hook-form';

export const ProfileEditingForm = ({ disabled, setProfile, }) => {
    const { register, formState: { errors, }, getValues, setValue, control, watch } = useFormContext();
    const indexType = watch('indexType');
    const dynamic = watch('dynamic');
    const term = watch('Term');

    function handleDateUpdate(key, value) {
        //make sure start date is before stop date
        const start = getValues('startDateTime');
        const stop = getValues('stopDateTime');
        if ((key === 'startDateTime' && dayjs(stop).isBefore(value)) || (key === 'stopDateTime' && dayjs(start).isAfter(value))) {
            setValue('startDateTime', value);
            setValue('stopDateTime', value);
        } else {
            setValue(key, value);
        }
    }

    function handleTimezoneUpdate(oldTz, newTz) {
        setValue('timezone', newTz);
        const offset = (dayjs().tz(newTz).utcOffset() - dayjs().tz(oldTz).utcOffset()) / 60;
        const newStart = dayjs(getValues('startDateTime')).add(offset, 'hours').startOf('hour');
        const newStop = dayjs(getValues('stopDateTime')).add(offset, 'hours').startOf('hour');
        setValue('startDateTime', newStart);
        setValue('stopDateTime', newStop);
    }

    return (
        <Grid container alignItems='center' sx={{ p: 2, }} spacing={2} columns={10}>

            <Grid item md={2}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <FormDateTimePicker
                        name='startDateTime'
                        control={control}
                        disabled={disabled}
                        label="Profile Start"
                        onAccept={(newValue) => handleDateUpdate('startDateTime', newValue?.startOf('hour'))}
                        views={['year', 'month', 'day', 'hours']}
                        format="MM/DD/YYYY HH:mm"
                        slotProps={{
                            textField: {
                                size: 'small',
                                error: !!errors.startDateTime,
                                helperText: errors.startDateTime?.message,
                                onBlur: (e) => {
                                    handleDateUpdate('startDateTime', dayjs(e.target.value).startOf('hour'));
                                }
                            }
                        }}
                    />
                </LocalizationProvider>
            </Grid>

            <Grid item md={2}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <FormDateTimePicker
                        name='stopDateTime'
                        control={control}
                        label="Profile Stop"
                        onAccept={(newValue) => handleDateUpdate('stopDateTime', newValue?.startOf('hour'))}
                        disabled={disabled}
                        views={['year', 'month', 'day', 'hours']}
                        format="MM/DD/YYYY HH:mm"
                        slotProps={{
                            textField: {
                                size: 'small',
                                error: !!errors.stopDateTime,
                                helperText: errors.stopDateTime?.message,
                                onBlur: (e) => {
                                    handleDateUpdate('stopDateTime', dayjs(e.target.value).startOf('hour'));
                                }
                            }
                        }}
                    />
                </LocalizationProvider>
            </Grid>

            <Grid item md={2}>
                <FormAutocomplete
                    autoHighlight
                    openOnFocus
                    disabled={disabled || term?.toLowerCase().includes('rockies')}
                    options={['America/Detroit', 'America/Chicago', 'America/Denver', 'America/Los_Angeles',]}
                    getOptionLabel={(option) => renderDayjsTimezones[option]}
                    onChange={(e, newValue) => {
                        const oldValue = getValues('timezone');
                        handleTimezoneUpdate(oldValue, newValue);
                    }}
                    control={control}
                    name='timezone'
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="outlined"
                            label="Timezone"
                            size='small'
                            color="success"
                            error={!!errors.timezone}
                            helperText={errors.timezone?.message}
                        />
                    )}
                />
            </Grid>

            <Grid item md={2}>
                <TextField
                    {...register('adder')}
                    onBlur={e => {
                        const value = e.target.value;
                        setValue('adder', value ? parseFloat(value).toFixed(2) : '');
                    }}
                    label="Adder"
                    size='small'
                    fullWidth
                    disabled={(indexType === 'None') || !dynamic || disabled}
                    error={!!errors.adder}
                    helperText={errors.adder?.message}
                    InputProps={{
                        endAdornment: <InputAdornment position="end">$</InputAdornment>,
                    }}
                />
            </Grid>

            <Grid item xs={2}>
                <Button
                    variant='contained'
                    color='primary'
                    fullWidth
                    onClick={() => {
                        setValue('Term', '');
                        setProfile([]);
                    }}
                    disabled={disabled}
                >Clear All</Button>
            </Grid>

        </Grid>
    );
}