import { useState, useCallback, } from 'react';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import ConfirmationDialog from '../../TSRActivity/ConfirmationDialog';
import debounce from 'lodash/debounce';
import { useApi } from '../../useApi';
import { RibbonButton } from '../Ribbon';
import ExtensionIcon from '@mui/icons-material/Extension';
import { useFormContext } from "react-hook-form";
import { Tooltip } from '@mui/material';


export const ExtendDealButton = ({ deal, disabled, formId, onRefresh, expanded, gridRef, ...buttonProps }) => {
    const [confirmationDialogProps, setConfirmationDialogProps] = useState({ open: false });
    const { apiUrlPrefix, headers, get, enqueueSnackbar, logAction, } = useApi();
    const buttonDebounce = 1500;
    const { handleSubmit } = useFormContext();

    function handleConfirmExtend() {
        const action = 'extend';
        setConfirmationDialogProps({
            open: true,
            message: `You are about to ${action} the selected deal (${deal.dealID}). Continue?`,
            onConfirmation: () => handleExtend(deal),
            onCancel: () => setConfirmationDialogProps({ open: false }),
        });
    }

    const handleExtend = useCallback(debounce(async (dealData) => {
        setConfirmationDialogProps({ open: false });
        // const toVoid = dealData.Status === 'VOIDED' ? 0 : 1;
        const url = `${apiUrlPrefix}/CrystalBall/Store/Shelf?name=dealrizz.UI_extendDeal`
            + `&parm=${headers.userGuid}`
            + `&parm=${dealData.dealID}`

        return get(url).then(response => {
            enqueueSnackbar(`Deal extended successfully.`, { variant: 'success' });
            logAction(`User extended deal with id ${dealData.dealID}`, 'Deal Summary');
            handleSubmit(onRefresh)();
            gridRef?.current.api?.deselectAll();
        });
    }, buttonDebounce, { leading: true, }), [headers, apiUrlPrefix]);

    return (
        <>
            <ConfirmationDialog {...confirmationDialogProps} />
            <Tooltip title={'Extend the selected deal.'}>
                <span>
                    <RibbonButton
                        id={`${formId}-refresh`}
                        onMouseDown={() => handleConfirmExtend()}
                        label="Extend Deal"
                        icon={<ExtensionIcon fontSize='small' />}
                        expanded={expanded}
                        disabled={disabled}
                        {...buttonProps}
                    />
                </span>
            </Tooltip>
        </>
    );
}