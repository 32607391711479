import dayjs from "dayjs";

export const formFieldDefs = [
    {
        key: 'Transaction_Type',
        label: 'Transaction Type',
        defaultValue: '',
    },
    {
        key: 'Transaction',
        label: 'Transaction',
        defaultValue: '',
    },
    {
        key: 'Counterparty',
        label: 'Counterparty',
        defaultValue: '',
    },
    {
        key: 'Index',
        label: 'Index',
        defaultValue: '',
    },
    {
        key: 'Term',
        label: 'Term',
        defaultValue: '',
    },
    {
        key: 'Book',
        label: 'Book',
        defaultValue: [],
    },
    {
        key: 'Contract',
        label: 'Contract',
        defaultValue: '',
    },
    {
        key: 'Financial_Type',
        label: 'Firm/Non-Firm',
        defaultValue: '',
    },
    {
        key: 'Market',
        label: 'Market',
        defaultValue: '',
    },
    {
        key: 'Zone',
        label: 'Zone',
        defaultValue: '',
    },
    {
        key: 'por',
        label: 'POR',
        defaultValue: '',
    },
    {
        key: 'pod',
        label: 'POD',
        defaultValue: '',
    },
    {
        key: 'Trader',
        label: 'Trader',
        defaultValue: '',
    },
    {
        key: 'dealToken',
        label: 'Deal Name',
        defaultValue: '',
    },
    {
        key: 'Trade_Date',
        label: 'Trade Date',
        defaultValue: null,
    },
    {
        key: 'Strategy',
        label: 'Strategy',
        defaultValue: [],
    },
    {
        key: 'comments',
        label: 'Comments',
        defaultValue: '',
    },
    {
        key: 'confirmed',
        label: 'Confirmed',
        defaultValue: true,
    },
    {
        key: 'dealStartTime',
        label: 'Deal Start',
        defaultValue: dayjs().add(1, 'hour').startOf('hour'),
    },
    {
        key: 'dealStopTime',
        label: 'Deal End',
        defaultValue: dayjs().add(1, 'hour').add(1, 'day').startOf('hour'),
    },
];