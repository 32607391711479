import { Stack, Box, IconButton, Typography, DialogTitle, Dialog, DialogContentText, DialogContent, DialogActions, Button, TextField, } from "@mui/material";
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { string, object, number, } from 'yup';
import FormAutocomplete from "../../FormControls/FormAutocomplete";
import { useMemo } from "react";
import CloseIcon from '@mui/icons-material/Close';
import { apiUrlPrefix } from "../../../authConfig";
import useHeader from "../../useHeader";
import { useSnackbar } from "notistack";
import axios from "axios";

export const AddAlertDialog = (props) => {
    const { open, handleClose, locations, } = props;
    const headers = useHeader();
    const { enqueueSnackbar } = useSnackbar();

    const defaultValues = useMemo(() => ({
        firstLocation: '',
        secondLocation: '',
        percentDelta: '',
    }), []);

    const { control, handleSubmit, reset, formState: { errors, }, register, } = useForm({
        defaultValues: defaultValues,
        resolver: yupResolver(schema),
    });

    function onSubmit(data) {
        handleClose();
        handleSaveNewAlert(data).then(response => {
            enqueueSnackbar('Alert successfully created.', { variant: 'success' });
            reset();
        });
    }

    async function handleSaveNewAlert(data) {
        const params = {
            timeZone: '',
            TargetExecutionTime: '',
            application: `Deal Rizz`,
            action: 'lmpAlert',
            data: data,
        }

        const url = `${apiUrlPrefix}/CrystalBall/Store/Shelf/JSON/Pushfetch?name=PowerStationMetaData.UI_TransactionQueueInsert&parm=${headers.userGuid}&parm=${params.timeZone}&parm=${params.TargetExecutionTime}&parm=${params.action}&parm=1&parm=${params.application}`

        const options = {
            method: 'POST',
            headers: headers,
            data: params.data,
            url: url,
        }

        return axios(options).catch(error => {
            var partsArray = error.response?.data.split('More info follows:');
            enqueueSnackbar(`ERR: ${partsArray[0]} MESSAGE: ${error.message} URL: ${options.url} `, { variant: 'error' })
        });
    }

    return (
        <Dialog open={open} maxWidth='md'>
            <DialogTitle>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography>Add New LMP Alert</Typography>
                    <Box sx={{ flexGrow: 1, }} />
                    <IconButton onClick={handleClose} size='large'>
                        <CloseIcon />
                    </IconButton>
                </Box>
            </DialogTitle>
            <DialogContent>
                <form onSubmit={handleSubmit(onSubmit)} id='add-lmp-alert-form'>
                    <Stack spacing={2}>
                        <DialogContentText>
                            Please select the LMPs to compare in your new alert.
                        </DialogContentText>
                        <FormAutocomplete
                            name="firstLocation"
                            control={control}
                            autoHighlight
                            options={locations}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    size="small"
                                    variant="outlined"
                                    label="First Location"
                                    color="success"
                                    error={!!errors.firstLocation}
                                    helperText={errors.firstLocation?.message}
                                />
                            )}
                        />
                        <FormAutocomplete
                            control={control}
                            name="secondLocation"
                            autoHighlight
                            options={locations}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    size="small"
                                    variant="outlined"
                                    label="Second Location"
                                    color="success"
                                    error={!!errors.secondLocation}
                                    helperText={errors.secondLocation?.message}
                                />
                            )}
                        />
                        <DialogContentText>
                            Choose the percentage difference between the two locations on which to alert.
                        </DialogContentText>
                        <TextField
                            {...register('percentDelta')}
                            label="Percent Delta"
                            size="small"
                            error={!!errors.percentDelta}
                            helperText={errors.percentDelta?.message}
                        />
                    </Stack>
                </form>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Cancel
                </Button>
                <Box sx={{ flexGrow: 1 }} />
                <Button type="submit" form='add-lmp-alert-form' color="primary">
                    Add
                </Button>
            </DialogActions>
        </Dialog>
    );
}

const schema = object().shape({
    firstLocation: string().required('Please select a location.'),
    secondLocation: string().required('Please select a location.'),
    percentDelta: number().typeError('This value must be a number.').required('Please enter a number.'),
});