import { IconButton, Stack, TextField, } from "@mui/material"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider, } from "@mui/x-date-pickers";
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import FormAutocomplete from "../../../FormControls/FormAutocomplete";
import FormDatePicker from "../../../FormControls/FormDatePicker";
import { useFormContext } from "react-hook-form";
import { RepublishViewButton } from "../../RibbonButtons/RepublishViewButton";
import { RibbonGroupContainer } from "../../Ribbon";

export const ViewForm = ({ onSubmit, formId, expanded, }) => {
    const { control, handleSubmit, getValues, setValue, formState: { errors, }, } = useFormContext();

    function handleIncrementDates(days) {
        const flowDate = getValues('flowDate');
        setValue('flowDate', flowDate.add(days, 'day'));
    }

    return (
        <form id={formId} onSubmit={handleSubmit(onSubmit)}>
            <RibbonGroupContainer>
                <RepublishViewButton
                    expanded={expanded}
                />

                <div>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <IconButton size='small' onMouseDown={() => {
                            handleIncrementDates(-1);
                        }}>
                            <ArrowLeftIcon />
                        </IconButton>
                        <FormDatePicker
                            name='flowDate'
                            control={control}
                            label="Flow Date"
                            slotProps={{
                                textField: {
                                    size: 'small',
                                    error: !!errors.flowDate,
                                    helperText: errors.flowDate?.message,
                                    sx: { width: '170px', }
                                }
                            }}
                        />
                        <IconButton size='small' onMouseDown={() => {
                            handleIncrementDates(1);
                        }}>
                            <ArrowRightIcon />
                        </IconButton>
                    </LocalizationProvider>
                </div>

                <FormAutocomplete
                    autoHighlight
                    options={['Pacific Standard Time', 'Eastern Standard Time', 'Central Standard Time', 'Mountain Standard Time']}
                    getOptionLabel={(option) => option.split(' ')[0]}
                    control={control}
                    name='timezone'
                    disableClearable
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="outlined"
                            label="Timezone"
                            size='small'
                            color="success"
                            error={!!errors.timezone}
                            helperText={errors.timezone?.message}
                            sx={{
                                minWidth: '170px',
                            }}
                        />
                    )}
                />

            </RibbonGroupContainer>
        </form>
    );
}