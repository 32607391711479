import { useState, useEffect, useMemo, useRef, useCallback } from "react";
import { AgGridContainer } from "../../../AgGrid/AgGridContainer";
import { useApi } from "../../../useApi";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, Typography, Box, IconButton, FormControlLabel, Checkbox } from "@mui/material";
import { useFormContext } from "react-hook-form";
import { ResupplyGrid } from "./ResupplyGrid";
import { ProfileChart } from "./ProfileChart";
import Graph from 'graphology';
import { allSimpleEdgeGroupPaths } from 'graphology-simple-path';
import CloseIcon from '@mui/icons-material/Close';
import ConfirmationDialog from "../../../TSRActivity/ConfirmationDialog";
import dayjs from "dayjs";
import debounce from "lodash/debounce";
import { useAbortController } from "../../../useAbortController";

export const ResupplyTagDialog = ({ open, onClose, selectedTag }) => {
    const [tsrs, setTsrs] = useState([]);
    const [loadingOverlayText, setLoadingOverlayText] = useState('Analyzing your TSR options...');
    const { get, headers, logAction, apiUrlPrefix, enqueueSnackbar, post } = useApi();
    const { watch, } = useFormContext();
    const gridRef = useRef();
    const [selectedPaths, setSelectedPaths] = useState([]);
    const [confirmationDialogProps, setConfirmationDialogProps] = useState({ open: false, });
    const timezone = watch('timezone');
    const [includeOriginals, setIncludeOriginals] = useState(false);
    const { abortControllerRef, abort } = useAbortController();

    const curtailmentProfile = useMemo(() => {
        if (selectedTag?.curtailmentProfile) {
            return JSON.parse(selectedTag.curtailmentProfile)
        } else {
            // Default profile if none exists
            return [{ "startDateTimeUtc": "09/25/2024 00:00", "endDateTimeUtc": "09/26/2024 00:00", "MWLevel": 0, "price": 0 }];
        }
    }, [selectedTag, tsrs]);

    useEffect(() => {
        if (selectedTag?.tagStatus?.toLowerCase() === 'curtailed') {
            fetchTsrs();
        }
    }, [selectedTag, includeOriginals]);

    async function fetchTsrs() {
        abort();//Cancel any pending requests
        gridRef.current?.api.showLoadingOverlay();
        setSelectedPaths([]);

        setLoadingOverlayText('Fetching TSRs to use in forming pathways...');

        const tsrTypes = includeOriginals ? 'RESERVED,REDIRECT,ORIGINAL' : 'RESERVED,REDIRECT';
        const url = `${apiUrlPrefix}/CrystalBall/Store/Shelf/JSON/postFetchArray?name=dealrizz.UI_fetchResupply`
            + `&parm=${headers.userGuid}`
            + `&parm=${selectedTag.firstPOR}`
            + `&parm=${selectedTag.lastPOD}`
            + `&parm=${timezone}`
            + `&parm=*`
            + `&parm=*`
            + `&parm=*`
            + `&parm=*`
            + `&parm=*`
            + `&parm=*`
            + `&parm=`
            + `&parm=`
            + `&parm=${tsrTypes}`

        return post(url, curtailmentProfile, { signal: abortControllerRef.current.signal, }).then(response => {
            gridRef.current.api.deselectAll();
            const newData = !!response?.data ? response.data : [];
            setTsrs(newData);
        });
    }

    const tsrKey = (tsr) => `${tsr.PostingRef}`;

    const availableNodes = useMemo(() => {
        return [...new Set(tsrs.reduce((nodes, next) => {
            nodes.add(next.PointOfReceipt);
            nodes.add(next.PointOfDelivery);
            return nodes;
        }, new Set()))].map(node => ({ key: node }));
    }, [tsrs]);

    const tsrKeyMap = useMemo(() => {
        return tsrs.reduce((map, tsr) => {
            map.set(tsrKey(tsr), tsr);
            return map;
        }, new Map());
    }, [tsrs]);

    const availableEdges = useMemo(() => {
        return tsrs.map(tsr => ({
            source: tsr.PointOfReceipt,
            target: tsr.PointOfDelivery,
            key: tsrKey(tsr),
            attributes: tsr,
        }));
    }, [tsrs]);

    const availabilityGraph = useMemo(() => {
        const graph = new Graph({ type: 'directed', multi: true });
        try {
            graph.import({
                nodes: availableNodes,
                edges: availableEdges,
            });
        } catch (error) {
            enqueueSnackbar(`Error creating availability graph. ${error}`, { variant: 'error' });
        }
        return graph;
    }, [availableEdges, availableNodes]);

    const edgeGroupPaths = useMemo(() => {
        try {
            const source = selectedTag?.firstPOR;
            const sink = selectedTag?.lastPOD;
            let edgePaths = [];
            if (source && sink && availabilityGraph.hasNode(source) && availabilityGraph.hasNode(sink)) {
                edgePaths = allSimpleEdgeGroupPaths(availabilityGraph, source, sink, { maxDepth: 3 });

                if (!edgePaths.length) {
                    enqueueSnackbar('No paths found between the POR and POD of the selected tag.', { variant: 'error' });
                    return [];
                }
            }

            return edgePaths;
        } catch (error) {
            enqueueSnackbar(`Error finding paths between POR and POD. ${error}`, { variant: 'error' });
            return [];
        }
    }, [availabilityGraph]);

    function getAllCombinations(arrays) {

        setLoadingOverlayText('Analyzing TSRs to form pathways...');

        if (arrays.length === 0) return [[]];

        const restCombinations = getAllCombinations(arrays.slice(1));

        return arrays[0].reduce((result, current) => {
            restCombinations.forEach(combination => {
                result.push([current, ...combination]);
            });
            return result;
        }, []);
    }

    const pathKey = (path) => {
        return path.reduce((key, tsr) => {
            return key + tsr.PostingRef;
        }, '');
    };

    const getPathName = (legs) => {
        return legs.reduce((path, leg, idx) => {
            if (idx === 0) {
                return `${leg.PointOfReceipt}-${leg.Provider}-${leg.PointOfDelivery}`;
            } else {
                return `${path}-${leg.Provider}-${leg.PointOfDelivery}`;
            }
        }, '');
    }

    const gridData = useMemo(() => {
        const tsrPaths = edgeGroupPaths.map(edgeGroupPath => {
            return edgeGroupPath.map(edgeGroup => {
                return edgeGroup.map(edge => {
                    const tsr = tsrKeyMap.get(edge);
                    return tsr;
                });
            });
        });

        const uniquePaths = tsrPaths.reduce((uniquePaths, tsrGroupPath) => {
            const pathCombinations = getAllCombinations(tsrGroupPath);
            return uniquePaths.concat(pathCombinations);
        }, []);

        return uniquePaths.reduce((data, path) => {
            const key = pathKey(path);
            path.forEach(tsr => {
                data.push({
                    ...tsr,
                    rowGroupKey: key,
                });
            });
            return data;
        }, []);
    }, [edgeGroupPaths, tsrKeyMap]);

    const pathProfiles = useMemo(() => {
        const remaining = curtailmentProfile.map(block => ({ x: block.startDateTimeUtc, y: block.MWLevel }));

        const profiles = selectedPaths.map(gridPathNode => {
            const profileMap = gridPathNode.allLeafChildren.reduce((profile, node) => {
                const tsrAvailability = JSON.parse(node.data.availabilityProfile ?? '[]');
                tsrAvailability.forEach(block => {
                    const key = block.StartTime;
                    const capacity = block.Capacity;
                    if (!profile.has(key)) {
                        profile.set(key, capacity);
                    } else {
                        profile.set(key, Math.min(profile.get(key), capacity));
                    }
                });
                return profile;
            }, new Map());

            return {
                key: gridPathNode.key,
                name: getPathName(gridPathNode.allLeafChildren.map(node => node.data)),
                profileMap: profileMap,
                postingRefs: gridPathNode.allLeafChildren.map(node => node.data.PostingRef),
            }
        });

        const partialProfiles = profiles.map((profile) => {
            const { profileMap } = profile;
            remaining.forEach(point => {
                const max = profileMap.get(point.x);
                if (max) {
                    const remainingCapacity = Math.max(0, point.y - max);
                    profileMap.set(point.x, Math.min(point.y, max));
                    point.y = remainingCapacity;
                }
            });
            return {
                ...profile,
                profileMap,
            };
        });

        return partialProfiles;
    }, [selectedPaths]);

    const handleSubmitResupply = useCallback(debounce(async (confirmed = 0) => {
        const data = pathProfiles.flatMap(profile => {
            const profileArr = Array.from(profile.profileMap.entries()).map(([start, capacity]) => ({
                startDateTime: dayjs(start).format('MM/DD/YYYY HH:mm'),
                stopDateTime: dayjs(start).add(1, 'hour').format('MM/DD/YYYY HH:mm'),
                capacityRequested: capacity,
            }));
            return profile.postingRefs.map(postingRef => {
                return {
                    PostingRef: postingRef,
                    profile: profileArr,
                };
            });
        });

        const params = {
            timezone,
            TargetExecutionTime: '',
            application: `Deal Rizz`,
            action: 'resupplyTag',
        }

        const url = `${apiUrlPrefix}/CrystalBall/Store/Shelf/JSON/Pushfetch?name=PowerStationMetaData.UI_TransactionQueueInsert_v2`
            + `&parm=${headers.userGuid}`
            + `&parm=${params.timezone}`
            + `&parm=${params.TargetExecutionTime}`
            + `&parm=${params.action}`
            + `&parm=${confirmed}`
            + `&parm=${params.application}`

        return post(url, data);
    }, 1000, { leading: true, trailing: false }), [pathProfiles, timezone]);

    function handleConfirmResupply() {
        handleSubmitResupply(0).then(response => {
            setConfirmationDialogProps({
                open: true,
                message: response.data,
                onCancel: () => setConfirmationDialogProps({ open: false, }),
                onConfirmation: () => handleSubmitResupply(1).then(() => {
                    enqueueSnackbar('Success submitting TSR(s) to the queue for processing by OASIS.');
                    onClose();
                }),
            });
        });
    }

    return (
        <Dialog
            open={!!open}
            onClose={onClose}
            fullWidth
            maxWidth='xl'
            keepMounted
        >
            <DialogTitle sx={{ py: 0.25 }}>
                <Stack spacing={2} direction='row' sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography variant='h6' sx={{ flexGrow: 1, }}>Resupply Tag</Typography>
                    <Box sx={{ flexGrow: 1, }} />
                    <FormControlLabel
                        label="Include Originals"
                        control={
                            <Checkbox
                                checked={includeOriginals}
                                onClick={() => {
                                    setIncludeOriginals(!includeOriginals);
                                }}
                            />
                        }
                    />
                    <IconButton onClick={onClose} size='large'>
                        <CloseIcon />
                    </IconButton>
                </Stack>
            </DialogTitle>
            <DialogContent sx={{ display: 'flex', flexDirection: 'column', height: '75vh', py: 0, px: 1, }}>
                <ConfirmationDialog {...confirmationDialogProps} />
                <Box sx={{ height: '30vh', pb: 1 }}>
                    <ProfileChart
                        curtailmentProfile={curtailmentProfile}
                        selectedPaths={pathProfiles}
                    />
                </Box>
                <AgGridContainer style={{ flexGrow: 1, }}>
                    <ResupplyGrid
                        ref={gridRef}
                        data={gridData}
                        setSelectedPaths={setSelectedPaths}
                        selectedPaths={selectedPaths}
                        loadingOverlayText={loadingOverlayText}
                        getPathName={getPathName}
                        handleCancelFetch={abort}
                    />
                </AgGridContainer>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    Cancel
                </Button>
                <Box sx={{ flexGrow: 1, }} />
                <Button onClick={handleConfirmResupply} color="primary" variant='contained' disabled={!selectedPaths?.length}>
                    Resupply Tag
                </Button>
            </DialogActions>
        </Dialog>
    );
};
