import { useEffect, createContext, useRef, useState, useContext, } from 'react';
import AddIcon from '@mui/icons-material/Add';
import "react-tabs/style/react-tabs.css"
import { Typography, Tab, Tabs, Box, Collapse, Tooltip, Divider, List, ListItem, } from '@mui/material';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import ConfirmationDialog from '../TSRActivity/ConfirmationDialog';
import EditIcon from '@mui/icons-material/Edit';
import { useUserLayouts } from "../useUserLayouts";
import { useActionAudit } from "../useActionAudit";
import { styled } from '@mui/material';
import { CircularProgress } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';

const DashboardContext = createContext();

export const useDashboard = () => {
    return useContext(DashboardContext);
}

export const DashboardProvider = (props) => {
    const { children, visible, dashboardKey, useShared, AddNewOrUpdateDialog, } = props;
    const { layouts: views, setLayouts: setViews, deleteLayout: handleDelete, insertUpdateLayout: handleSave, loading, } = useUserLayouts(dashboardKey, useShared);
    const params = useParams();
    const viewId = params.id;
    const { logAction } = useActionAudit();
    const navigate = useNavigate();
    const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
    const [openAddNew, setOpenAddNew] = useState(false);
    const currentView = views.find(v => v.id.toString() === viewId) ?? {};
    const viewsRef = useRef([]);
    const { enqueueSnackbar } = useSnackbar();

    //if there is no viewId in the URL, set id to the id of the first view
    useEffect(() => {
        if (!viewId && views.length && visible) {
            navigate(`./${views[0].id}`, { relative: 'path' });
        }
    }, [viewId, views, visible]);

    function handleConfirmDelete(view) {
        setOpenConfirmDelete(false);
        localStorage.removeItem(`deal-rizz-${dashboardKey}-grid-layout-${view.id}`);
        handleDelete(view.id).then(response => {
            const remaining = views?.filter(v => v.id !== view.id);
            if (remaining?.length === 0) { //if they deleted the last view, show Add New dialog
                setOpenAddNew(true);
            }
            navigate(`../${remaining[0]?.id ?? ''}`, { relative: 'path' });
            logAction(`User deleted the ${dashboardKey} view ${view.label}`, dashboardKey, JSON.stringify(view));
        });
    }

    async function handleAddNewOrUpdate(view = currentView) {
        const isExistingView = view.id;
        if (isExistingView) { //update it
            const oldView = views.find(v => v.id === view.id);
            logAction(`User updated the ${dashboardKey} view ${oldView.label}`, dashboardKey, `Old view data: ${JSON.stringify(oldView)} New view data: ${JSON.stringify(view)}`);
            //if (viewToSave.shared) {
            //also save the layout
            const idx = views.findIndex(v => v.id === view.id);
            const layout = viewsRef.current[idx]?.captureLayout();
            const viewToSave = { ...view, layout };
            //}
            handleUpdate(viewToSave);
        } else { //create a new one
            logAction(`User created a new ${dashboardKey} view ${view.label}`, dashboardKey, JSON.stringify(view));
            handleSave(view).then(response => {
                const newId = response?.data;
                if (viewId === 'add-new') {
                    navigate(`../${newId}`, { relative: 'path' });
                } else {
                    navigate(`./${newId}`, { relative: 'path' });
                }
            });
        }
        setOpenAddNew(false);
    }

    function handleUpdate(criteria) {
        setViews(views.map(view => view.id === criteria.id ? criteria : view));
        handleSave(criteria, false).then(response => {
            enqueueSnackbar('View updated successfully.', { variant: 'success' });
        });
    }

    function handleCancelAddNew() {
        setOpenAddNew(false);
        if (!currentView.id) {
            navigate(-1);
        }
    }

    return (
        <DashboardContext.Provider value={{ viewsRef, visible, handleConfirmDelete, handleAddNewOrUpdate, views, loading, dashboardKey, setOpenConfirmDelete, setOpenAddNew, }}>
            <AddNewOrUpdateDialog
                open={openAddNew || viewId === 'add-new'}
                viewToUpdate={currentView}
                handleCancel={handleCancelAddNew}
                handleAddNew={handleAddNewOrUpdate}
                allViewLabels={views.map(v => v.label)}
            />
            <ConfirmationDialog
                open={openConfirmDelete}
                message={`You are about to delete the current tab${currentView?.label ? ' ' + currentView.label : ''}. Continue?`}
                onCancel={() => setOpenConfirmDelete(false)}
                onConfirmation={() => handleConfirmDelete(currentView)}
            />
            {children}
        </DashboardContext.Provider>
    );
}

export const Dashboard = ({ dashboardKey, useShared = false, ViewComponent, AddNewOrUpdateDialog, visible, }) => {
    return (
        <DashboardProvider visible={visible} dashboardKey={dashboardKey} useShared={useShared} AddNewOrUpdateDialog={AddNewOrUpdateDialog}>
            <Box
                sx={{
                    p: '7px',
                    flexGrow: 1,
                }}
            >
                <Box>
                    <DashboardTabs
                        AddNewOrUpdateDialog={AddNewOrUpdateDialog}
                    />
                    <DashboardViewContainer
                        ViewComponent={ViewComponent}
                    />
                </Box>
                <NoDataOverlay />
                <LoadingOverlay />
            </Box>
        </DashboardProvider>
    );
}

const NoDataOverlay = () => {
    const { loading, views } = useDashboard();
    const visible = !loading && !views.length;
    return (
        <OverlayContainer visible={visible}>
            <Typography> No views found.  Click Add New in the upper left to create a new view.  </Typography>
        </OverlayContainer>
    );
}

const LoadingOverlay = () => {
    const { loading } = useDashboard();
    return (
        <OverlayContainer visible={loading}>
            <CircularProgress />&nbsp;&nbsp;
            <Typography align='center' color='primary' variant='h6'>Loading...</Typography>
        </OverlayContainer>
    );
}

const DashboardTabs = () => {
    const { views, dashboardKey, setOpenConfirmDelete, setOpenAddNew, } = useDashboard();
    const params = useParams();
    const viewId = params.id;
    const tabIndex = Math.max(views.findIndex(view => view.id.toString() === viewId), 0);
    const navigate = useNavigate();

    function handleAddNewClick() {
        setOpenAddNew(true);
    }

    function handleTabSelect(event, newIndex) {
        if (newIndex < views.length + 1) { //don't switch to the edit or delete tabs
            const id = views[newIndex]?.id ?? 'add-new';
            navigate(`../${id}`, { relative: 'path' });
        }
    }

    function handleViewUpdate() {
        setOpenAddNew(true);
    }

    return (
        <Tabs
            value={tabIndex}
            onChange={handleTabSelect}
            indicatorColor="primary"
            textColor="primary"
            color="primary"
            sx={{ maxHeight: '40px', minHeight: '40px' }}
        >
            {views.map((view) => (
                <Tooltip title={<Box sx={{ pt: 2, }}>
                    <Divider>View Info</Divider>
                    <List dense sx={{ fontSize: '1rem', }}>
                        <ListItem>Created by: {view.userName}</ListItem>
                        <ListItem>Is Shared: {!!view.shared ? 'True' : 'False'}</ListItem>
                    </List>
                    <Divider />
                </Box>} key={`${dashboardKey}-dashboard-tab-${view.id}`} placement='bottom' arrow>
                    <Tab
                        sx={{ maxHeight: '40px', minHeight: '40px' }}
                        label={view.label}
                    //key={`${dashboardKey}-dashboard-tab-${view.id}`}
                    />
                </Tooltip>
            ))}
            <Tab key='addNewTab' label='Add New' icon={<AddIcon />} iconPosition='start' onClick={handleAddNewClick} sx={{ maxHeight: '40px', minHeight: '40px' }} />
            <Tab key='editCurrentTab' label='Edit Current' icon={<EditIcon />} iconPosition='start' onClick={handleViewUpdate} sx={{ maxHeight: '40px', minHeight: '40px' }} disabled={!views[tabIndex]} />
            <Tab key='deleteCurrentTab' label='Delete Current' icon={<RemoveCircleOutlineIcon />} iconPosition='start' onClick={() => setOpenConfirmDelete(true)} sx={{ maxHeight: '40px', minHeight: '40px' }} disabled={!views[tabIndex]} />
        </Tabs>
    );
}

const DashboardViewContainer = ({ ViewComponent, }) => {
    const { views, dashboardKey, viewsRef, } = useDashboard();
    const params = useParams();
    const currentViewId = params.id;

    return (
        <Box
            sx={{
                flexGrow: 1,
            }}
        >
            {views.map((view, i) => {
                return (
                    <Collapse
                        in={currentViewId === view.id.toString()}
                        orientation={'vertical'}
                        key={`${view.id}-${dashboardKey}-view`}
                    >
                        <ViewComponent
                            view={view}
                            ref={el => viewsRef.current[i] = el}
                        />
                    </Collapse>
                )
            })}
        </Box>
    );
}

const OverlayContainer = styled('div', {
    shouldForwardProp: (prop) => prop !== 'visible',
})(({ theme, visible }) => ({
    position: 'absolute',
    top: '45%',
    left: 0,
    width: '100%',
    display: visible ? 'flex' : 'none',
    justifyContent: 'center',
    alignItems: 'center',
}));