import { useImperativeHandle, useRef, useMemo, useState, useCallback, forwardRef } from "react"
import { Box } from "@mui/material"
import dayjs from "dayjs"
import { useApi } from "../../useApi"
import { useDashboardFetch } from "../useDashboardFetch"
import { DealSummaryRibbon } from "./Ribbon/DealSummaryRibbon"
import { ViewContextProvider } from "../ViewContextProvider"
import * as yup from 'yup';
import { DealSummaryGrid } from "./DealSummaryGrid"
import { useLayoutFunctions } from "../../useLayoutFunctions"

const schema = yup.object().shape({
    timezone: yup.string().required('Timezone is required'),
    startDate: yup.date().required('Start Date is required'),
    endDate: yup.date().required('End Date is required'),
});

export const DealSummaryView = forwardRef(({ view, }, ref) => {
    const gridRef = useRef();
    const [selectedRow, setSelectedRow] = useState();
    const { headers, logAction, } = useApi();
    const toolbarFormId = `deal-summary-toolbar-form-${view.id}`;
    const layoutStorageKey = `deal-rizz-deal-summary-grid-layout-${view.id}`;
    const { captureLayout } = useLayoutFunctions();

    const defaults = useMemo(() => ({
        timeType: 'daily',
        timezone: 'Pacific Standard Time',
        ...view,
        startDate: dayjs(),
        endDate: dayjs().add(1, 'day'),
    }), [view]);

    const getRowId = useCallback(({ data }) => data.dealID, []);

    const dataFormatter = useCallback((data) => ({
        ...data,
        userGuid: headers.userGuid,
        startDate: dayjs(data.startDate).format('MM/DD/YYYY'),
        endDate: dayjs(data.endDate).format('MM/DD/YYYY'),
    }), [headers]);

    const { loadAndApplyData, silentUpdate, } = useDashboardFetch({
        fetchProcName: 'dealrizz.UI_fetchDealSummary_v4',
        fetchParamKeys: ['userGuid', 'startDate', 'endDate', 'timezone'],
        gridRef,
        getRowId,
        dataFormatter,
    });

    function handleFetchData(data) {
        logAction('User fetched Deal Summary Data', 'Deal Summary', data);
        loadAndApplyData(data);
    }

    useImperativeHandle(ref, () => {
        return {
            captureLayout: () => captureLayout(gridRef),
        };
    });

    return (
        <ViewContextProvider schema={schema} defaults={defaults} onSubmit={handleFetchData}>
            <Box sx={{ height: '86vh', display: 'flex', flexDirection: 'column' }}>
                <DealSummaryRibbon
                    selectedRow={selectedRow}
                    gridRef={gridRef}
                    toolbarFormId={toolbarFormId}
                    handleFetchData={handleFetchData}
                />
                <DealSummaryGrid
                    setSelectedRow={setSelectedRow}
                    getRowId={getRowId}
                    layoutStorageKey={layoutStorageKey}
                    ref={gridRef}
                    silentUpdate={silentUpdate}
                />
            </Box>
        </ViewContextProvider>
    )
});