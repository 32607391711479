import { defaultGridOptions, defaultColumnDef, defaultStatusBar } from "../../../AgGrid/defaultGridProps";
import { AgGridReact } from "ag-grid-react";
import { useMemo, forwardRef, useCallback, } from "react";
import moment from "moment";
import { LayoutToolPanel } from "../../../ToolPanels/LayoutToolPanel";
import { columnPanel, filterPanel } from "../../../ToolPanels/DefaultToolPanels";
import dayjs from "dayjs";
import { useTheme } from "@mui/styles";
import { Button } from "@mui/material";

export const ResupplyGrid = forwardRef(({ data, selectedPaths, loadingOverlayText, setSelectedPaths, getPathName, handleCancelFetch, }, ref) => {
    const theme = useTheme();

    const defaultColDefs = useMemo(() => ({
        ...defaultColumnDef,
        editable: false,
    }), []);

    function tooltipRenderer(params) {
        const { xValue, yValue } = params;
        const tzDate = moment(xValue).format('MM/DD/YY HH:mm');
        return {
            title: tzDate,
            content: yValue,
        };
    }

    const markerFormatter = (params) => {
        const { highlighted } = params;
        const { red, green } = theme.palette.primary;
        return {
            size: highlighted ? 3 : 1,
            enabled: true,
            fill: params.yValue <= 0 ? red : green,
            stroke: params.yValue <= 0 ? red : green,
        };
    };

    function configureProfileSparkline(availabilityData) {
        const availabilityMap = availabilityData.reduce((map, point) => {
            map.set(point.StartTime, point.Capacity);
            return map;
        }, new Map());

        const availabilityDataArr = Array.from(availabilityMap).reduce((acc, [x, y]) => {
            const startPoint = { x: moment(moment.parseZone(x).format('MM/DD/YYYY HH:mm')).toDate(), y };
            const stopPoint = { x: moment(moment.parseZone(x).add(1, 'hour').format('MM/DD/YYYY HH:mm')).toDate(), y };
            acc.push(startPoint);
            acc.push(stopPoint);
            return acc;
        }, []);

        return availabilityDataArr;
    }

    const generateMinimumProfile = (profiles) => {
        const minProfileMap = profiles.reduce((minMap, sparkline) => {
            sparkline.forEach(point => {
                const { StartTime, StopTime, Capacity } = point;
                if (minMap.has(StartTime)) {
                    const min = minMap.get(StartTime);
                    minMap.set(StartTime, Math.min(min, Capacity));
                } else {
                    minMap.set(StartTime, Capacity);
                }

                if (minMap.has(StopTime)) {
                    const min = minMap.get(StopTime);
                    minMap.set(StopTime, Math.min(min, Capacity));
                } else {
                    minMap.set(StopTime, Capacity);
                }
            });

            return minMap;
        }, new Map());

        const minProfileArr = Array.from(minProfileMap);

        const sortedArr = minProfileArr.toSorted(([a], [b]) => moment(a).diff(moment(b)));

        const combinedSparklineData = sortedArr.reduce((acc, [x, y], i) => {
            const startPoint = { x: moment(moment.parseZone(x).format('MM/DD/YYYY HH:mm')).toDate(), y };
            const stopPoint = { x: moment(moment.parseZone(x).add(1, 'hour').format('MM/DD/YYYY HH:mm')).toDate(), y };
            acc.push(startPoint);
            if (i < minProfileMap.size - 1) {
                acc.push(stopPoint);
            }
            return acc;
        }, [])

        return combinedSparklineData;
    };

    const groupOrderComparator = (nodeAKey, nodeBKey) => {
        if (!nodeAKey || !nodeBKey) {
            return 0; // maintain existing order on non group nodes
        }

        const paths = ref.current.api.getSelectedNodes();
        //check if nodeA and nodeB are selected
        const selectedA = paths.some(node => node.key === nodeAKey);
        const selectedB = paths.some(node => node.key === nodeBKey);

        if (selectedA && !selectedB) {
            return -1; // nodeA comes before nodeB
        } else if (!selectedA && selectedB) {
            return 1; // nodeB comes before nodeA
        } else if (selectedA && selectedB) {
            //the one with the smaller index comes first, i.e. the one that was selected first
            return paths.findIndex(node => node.key === nodeAKey) - paths.findIndex(node => node.key === nodeBKey);
        } else {
            return 0; // maintain existing order
        }
    };

    const colDefs = useMemo(() => [
        {
            field: 'rowGroupKey',
            hide: true,
            rowGroup: true,
            suppressColumnsToolPanel: true,
            filter: 'agSetColumnFilter',
            valueFormatter: (params) => {
                if (params.node.group) {
                    const legs = params.node.allLeafChildren.map(node => node.data);
                    return getPathName(legs);
                } else {
                    return params.value;
                }
            },
        },
        {
            headerName: 'Leg Score',
            field: 'legScore',
            aggFunc: 'sum',
            sortable: true,
            sort: 'desc',
            sortIndex: 1,
        },
        {
            headerName: 'Type',
            field: 'RequestType',
            initialWidth: 100,
        },
        {
            headerName: 'Customer Code',
            field: 'CustomerCode',
            initialWidth: 100,
        },
        {
            headerName: 'TP',
            field: 'Provider',
            initialWidth: 80,
        },
        {
            headerName: 'POR',
            field: 'PointOfReceipt',
            initialWidth: 100,
        },
        {
            headerName: 'POD',
            field: 'PointOfDelivery',
            initialWidth: 100,
        },
        {
            headerName: 'Path Name',
            field: 'PathName',
            initialWidth: 220,
        },
        {
            headerName: 'Posting Ref',
            field: 'PostingRef',
            initialWidth: 100,
        },
        {
            headerName: 'Availability',
            field: 'Availability',
            initialWidth: 100,
            initialHide: true,
        },
        {
            headerName: 'NERC Priority',
            field: 'NERCcurtailmentPriority',
            initialWidth: 120,
            aggFunc: params => {
                const values = params.values;
                const min = Math.min(...values);
                const max = Math.max(...values);
                return `Min: ${min}, Max: ${max}`;
            }
        },
        {
            headerName: "ATC Future",
            cellRenderer: 'agSparklineCellRenderer',
            //field: 'atcFuture',
            initialHide: false,
            minWidth: 400,
            valueGetter: (params) => {
                const json = params.data?.availabilityProfile;
                const isGrouped = params.node.group;
                if (isGrouped) {
                    const profiles = params.node.allLeafChildren.map(child => JSON.parse(child.data.availabilityProfile));
                    return generateMinimumProfile(profiles);
                } else if (json) {
                    const profile = JSON.parse(json);
                    return configureProfileSparkline(profile);
                } else {
                    return [];
                }
            },
            /*aggFunc: params => {
                return generateMinimumProfile(params.values);
            },*/
            cellRendererParams: {
                sparklineOptions: {
                    type: 'area',
                    axis: {
                        type: 'time',
                    },
                    tooltip: {
                        renderer: tooltipRenderer,
                    },
                    marker: {
                        formatter: markerFormatter,
                    },
                },
            },
        },
        {
            headerName: 'Ceiling Price',
            field: 'CeilingPrice',
            initialWidth: 100,
            initialHide: true,
        },
        {
            headerName: 'Offer Price',
            field: 'OfferPrice',
            initialWidth: 100,
            initialHide: true,
        },
        {
            headerName: 'Start Time',
            field: 'StartTimeUtc',
            initialWidth: 120,
            valueFormatter: params => params.value ? moment.parseZone(params.value).format('MM/DD/YYYY  HH:mm') : '',
        },
        {
            headerName: 'Stop Time',
            field: 'StopTimeUtc',
            initialWidth: 120,
            valueFormatter: params => params.value ? moment.parseZone(params.value).format('MM/DD/YYYY  HH:mm') : '',
        },
        {
            headerName: 'TS Class',
            field: 'TSClass',
            initialWidth: 100,
        },
        {
            headerName: 'Increment',
            field: 'ServiceIncrement',
            initialWidth: 100,
        },
        {
            headerName: 'TS Type',
            field: 'TSType',
            initialWidth: 120,
        },
        {
            headerName: 'TS Period',
            field: 'TSPeriod',
            initialWidth: 100,
        },
        {
            headerName: 'TS Window',
            field: 'TSWindow',
            initialWidth: 100,
        },
        {
            headerName: 'TS Subclass',
            field: 'TSSubclass',
            initialWidth: 100,
        },
    ], []);

    const sideBar = useMemo(() => ({
        toolPanels: [
            columnPanel,
            filterPanel,
        ]
    }), []);

    //maps the posting ref of a tsr to an array of all the groups that contain that tsr
    const tsrGroupMap = useMemo(() => {
        return data.reduce((map, row) => {
            if (!map.has(row.PostingRef)) {
                map.set(row.PostingRef, []);
            }
            map.get(row.PostingRef).push(row.rowGroupKey);
            return map;
        }, new Map());
    }, [data]);

    const allGroupKeys = useMemo(() => {
        return data.reduce((set, row) => {
            set.add(row.rowGroupKey);
            return set;
        }, new Set());
    }, [data]);

    function updateRemainingProfileData(selectedNodes, previousSelectedNodes) {
        const api = ref.current.api;
        const newNodes = selectedNodes.filter(node => !previousSelectedNodes.includes(node));
        const removedNodes = previousSelectedNodes.filter(node => !selectedNodes.includes(node));
        const availabilityMap = new Map();

        // For the removed nodes, we need to add back the profile that we subtracted
        updateAvailabilityProfiles(removedNodes, addProfiles, availabilityMap);

        // For the new nodes, we need to subtract the profile
        updateAvailabilityProfiles(newNodes, subtractProfiles, availabilityMap);

        let toUpdate = [];
        let toRemove = new Set();
        api.forEachNode(node => {
            const key = node.data?.PostingRef + node.data?.rowGroupKey;
            if (!node.group && availabilityMap.has(key)) {
                const allZero = availabilityMap.get(key).every(point => point.Capacity <= 0);
                if (allZero) {
                    toRemove.add(node.data.rowGroupKey);
                }
                toUpdate.push({ ...node.data, availabilityProfile: JSON.stringify(availabilityMap.get(key)) });
            }
        });

        if (toUpdate.length > 0) {
            api.applyTransaction({ update: toUpdate, });
        }

        // Get the current filter model for the column
        const filterInstance = api.getFilterInstance('rowGroupKey');

        if (filterInstance) {
            // Get all unique values in the column
            // Filter the values, excluding those in valuesToFilterOut
            const filteredValues = [...allGroupKeys.difference(toRemove)];

            // Set the model to include only the filtered values
            filterInstance.setModel({
                values: filteredValues,
                filterType: 'set'
            });

            // Apply the filter and refresh
            api.onFilterChanged();
        }
    }

    const updateAvailabilityProfiles = (nodes, operation, availabilityMap) => {
        nodes.forEach(groupNode => {
            const childRows = groupNode.allLeafChildren.map(node => node.data);
            childRows.forEach(childRow => {
                const pathGroups = tsrGroupMap.get(childRow.PostingRef) ?? [];
                pathGroups.forEach(groupKey => {
                    if (groupKey === groupNode.key) { return; }
                    const rowKey = `${childRow.PostingRef}${groupKey}`;
                    let rowProfile;
                    if (!availabilityMap.has(rowKey)) {
                        const rowNode = ref.current.api.getRowNode(rowKey);
                        if (rowNode) {
                            rowProfile = JSON.parse(rowNode.data?.availabilityProfile ?? '[]');
                        }
                    } else {
                        rowProfile = availabilityMap.get(rowKey);
                    }
                    if (rowProfile) {
                        const childProfile = JSON.parse(childRow.availabilityProfile);
                        const newProfile = operation(rowProfile, childProfile);
                        availabilityMap.set(rowKey, newProfile);
                    }
                });
            });
        });
    };

    function subtractProfiles(profile1, profile2) {
        return combineProfilesPointwise(profile1, profile2, (a, b) => a - b);
    }

    function addProfiles(profile1, profile2) {
        return combineProfilesPointwise(profile1, profile2, (a, b) => a + b);
    }

    const combineProfilesPointwise = (profile1, profile2, operation) => {
        const profile2Map = profile2.reduce((map, point) => {
            map.set(dayjs(point.StartTime).format('MM/DD/YYYY HH:mm'), point.Capacity);
            return map;
        }, new Map());

        return profile1.reduce((acc, point) => {
            const key = dayjs(point.StartTime).format('MM/DD/YYYY HH:mm');
            const value = profile2Map.get(key) ?? 0;
            const resultingCapacity = operation(point.Capacity, value);
            acc.push({ StartTime: point.StartTime, StopTime: point.StopTime, Capacity: resultingCapacity });
            return acc;
        }, []);
    };

    const isRowSelectable = useCallback((node) => {
        return !!node.group;
    }, []);

    const getRowId = useCallback(({ data }) => {
        return data.PostingRef + data.rowGroupKey;
    }, []);

    const onSelectionChanged = useCallback((params) => {
        const selectedNodes = params.api.getSelectedNodes();
        setSelectedPaths(selectedNodes);
        updateRemainingProfileData(selectedNodes, selectedPaths);
        params.api.refreshClientSideRowModel('sort');
    }, [selectedPaths, tsrGroupMap, allGroupKeys]);

    const autoGroupColumnDef = useMemo(() => ({
        headerName: '',
        minWidth: 200,
        sort: 'asc',
        sortIndex: 0,
        comparator: groupOrderComparator,
        cellRendererParams: {
            suppressCount: true,
            checkbox: true,
        },
        cellRenderer: 'agGroupCellRenderer',
    }), []);

    const loadingOverlay = props => {
        return <div style={{ position: 'relative', fontSize: '20px', top: '50%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            {loadingOverlayText}
            <Button onClick={handleCancelFetch} variant="contained" sx={{ maxWidth: '200px', mt: 2, }}>Cancel</Button>
        </div>
    }

    return (
        <AgGridReact
            {...defaultGridOptions}
            ref={ref}
            suppressModelUpdateAfterUpdateTransaction
            defaultColDef={defaultColDefs}
            onSelectionChanged={onSelectionChanged}
            isRowSelectable={isRowSelectable}
            getRowId={getRowId}
            rowData={data}
            columnDefs={colDefs}
            sideBar={sideBar}
            statusBar={defaultStatusBar}
            groupDisplayType="multipleColumns"
            suppressAggFuncInHeader={true}
            autoGroupColumnDef={autoGroupColumnDef}
            rowSelection="multiple"
            // overlayLoadingTemplate={`<span style="width: 70%; font-size: 20px"></span>`}
            overlayNoRowsTemplate={`<span style="width: 70%; font-size: 20px">No pathways with available transmission were found.</span>`}
            //overlayLoadingTemplate={`<span style="width: 70%; font-size: 20px">${loadingOverlayText}</span>`}
            loadingOverlayComponent={loadingOverlay}
            components={{
                layoutToolPanel: LayoutToolPanel,
            }}
        />
    );
});
