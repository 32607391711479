import { Accordion, AccordionSummary, AccordionDetails, Typography, Stack } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { AgGridContainer } from "../../AgGrid/AgGridContainer";
import { AgGridReact } from "ag-grid-react";
import { useEffect, useMemo, useRef, useState, useCallback } from "react";
import { defaultColumnDef, defaultGridOptions } from "../../AgGrid/defaultGridProps";
import dayjs from "dayjs";
import { useApi } from "../../useApi";
import { styled } from "@mui/styles";
import { useColumnTypes } from "../../AgGrid/useColumnTypes";
import { useTheme } from "@mui/material";
import { columnPanel } from "../../ToolPanels/DefaultToolPanels";
import debounce from "lodash/debounce";
import { useFormContext } from "react-hook-form";

export const ProfileDetailRenderer = (props) => {
    const { data, node, api, setSelectedAdjustment } = props;
    const { get, headers, post, enqueueSnackbar, apiUrlPrefix, } = useApi();
    const [transData, setTransData] = useState([]);
    const [adjustmentData, setAdjustmentData] = useState();
    const adjustmentsGridRef = useRef();
    const transGridRef = useRef();
    const { columnTypes } = useColumnTypes({ dateFormat: 'MM/DD/YYYY' });
    const theme = useTheme();
    const tagId = data.tagIdx;
    const { watch } = useFormContext();
    const timezone = watch('timezone');
    const startDate = watch('startDate');
    const stopDate = watch('stopDate');

    useEffect(() => {
        handleSetDefaultTransData();
        fetchAdjustments();
    }, []);

    function handleSetDefaultTransData() {
        fetchTransmissionProfiles();
    }

    async function fetchTransmissionProfiles() {
        transGridRef.current?.api?.showLoadingOverlay();
        const url = `${apiUrlPrefix}/CrystalBall/Store/Shelf?name=dealrizz.UI_TagReport_transAllocForTag_v2`
            + `&parm=${headers.userGuid}`
            + `&parm=${timezone}`
            + `&parm=${dayjs(startDate).format('MM/DD/YYYY')}`
            + `&parm=${dayjs(stopDate).format('MM/DD/YYYY')}`
            + `&parm=${tagId}`;
        return get(url).then(response => {
            setTransData(response?.data ?? []);
        }).finally(() => {
            transGridRef.current?.api?.hideOverlay();
        });
    }

    async function fetchAdjustments() {
        adjustmentsGridRef.current?.api?.showLoadingOverlay();
        const url = `${apiUrlPrefix}/CrystalBall/Store/Shelf?name=dealrizz.UI_TagReport_adjsForTag`
            + `&parm=${headers.userGuid}`
            + `&parm=${timezone}`
            + `&parm=${dayjs(startDate).format('MM/DD/YYYY')}`
            + `&parm=${dayjs(stopDate).format('MM/DD/YYYY')}`
            + `&parm=${tagId}`;
        return get(url).then(response => {
            setAdjustmentData(response?.data ?? []);
        }).finally(() => {
            adjustmentsGridRef.current?.api?.hideOverlay();
        });
    }

    const adjustmentColDefs = useMemo(() => ([
        { field: 'RequestStatus', headerName: 'Status', checkboxSelection: true, },
        { field: 'Date', type: 'dateColumn', },
        { field: 'Entity' },
        ...Array.from({ length: 26 }, (_, i) => ({
            field: `${i + 1}`,
            enableRowGroup: false,
            //aggFunc: 'sum',
            minWidth: 75,
            chartDataType: 'series',
            type: 'numericColumn',
            //cellStyle: params => heatmapStyle(params, paletteRef),
        })),
    ]), []);

    const transProfileColDefs = useMemo(() => ([
        { field: 'tagIdx', headerName: 'Tag Index', initialHide: true, },
        { field: 'contract', headerName: 'Contract' },
        { field: 'POD' },
        { field: 'POR' },
        { field: 'Type', },
        { field: 'Date', type: 'dateColumn', },
        { field: 'TP' },
        { field: 'product', headerName: 'Product' },
        { field: 'transCust', headerName: 'Trans Cust' },
        ...Array.from({ length: 26 }, (_, i) => ({
            field: `${i + 1}`,
            enableRowGroup: false,
            editable: true,
            //aggFunc: 'sum',
            minWidth: 75,
            chartDataType: 'series',
            type: 'numericColumn',
            cellDataType: 'number',
            //cellStyle: params => heatmapStyle(params, paletteRef),
        })),
        { field: 'transmissionAllocationId', headerName: 'Trans Alloc Id', },
    ]), []);

    const defaultColDef = useMemo(() => ({
        ...defaultColumnDef,
        minWidth: 100,
        flex: 1,
    }), []);

    const sideBar = useMemo(() => ({
        toolPanels: [
            columnPanel,
        ]
    }), []);

    const gridOptions = useMemo(() => ({
        ...defaultGridOptions,
        defaultColDef,
        columnTypes,
        domLayout: 'autoHeight',
        rowClassRules: {
            'ag-row-draft-tsr': params => params.data.Type?.toLowerCase() === 'draft' && theme.palette.mode === 'light',
            'ag-row-draft-tsr-dark': params => params.data.Type?.toLowerCase() === 'draft' && theme.palette.mode === 'dark',
        },
    }), [defaultColDef, theme.palette.mode]);

    function handleCellValueChanged(params) {
        const { data, } = params;
        handleTagTxUpdate(data);
    }

    const handleTagTxUpdate = useCallback(debounce((data) => {
        const url = `${apiUrlPrefix}/CrystalBall/Store/Shelf/JSON/Push?name=dealrizz.UI_TagReport_ModifyTxOnDraftTag`
            + `&parm=${headers.userGuid}`
            + `&parm=${tagId}`
            + `&parm=${timezone}`

        post(url, data).then(response => {
            enqueueSnackbar('Allocation modification saved.', { variant: 'info' });
        });
    }, 300), [headers, timezone, tagId]);

    function handleAdjustmentSelectionChanged() {
        const selected = adjustmentsGridRef.current.api.getSelectedRows();
        setSelectedAdjustment(selected[0]);
    }

    const transGridHeightRef = useRef();
    const adjustmentsGridHeightRef = useRef();
    const transGridContainerId = 'trans-detail-grid-display-container';
    const adjustmentsGridContainerId = 'adjustments-detail-grid-display-container';
    //lock in the height of the div container to prevent resizing to fit the tool panel
    function onToolPanelVisibleChanged(containerId) {
        const ref = containerId.includes('trans') ? transGridRef : adjustmentsGridRef;
        const heightRef = containerId.includes('trans') ? transGridHeightRef : adjustmentsGridHeightRef;
        if (ref.current.api.isToolPanelShowing()) {
            const container = document.getElementById(containerId);
            container.setAttribute('style', `width: 100%; font-size: 10px; height: ${heightRef.current + 220}px`);
            ref.current.api.setDomLayout('normal');
        } else {
            const container = document.getElementById(containerId);
            container.setAttribute('style', `width: 100%; font-size: 10px; height: ${heightRef.current - 220}px`);
            ref.current.api.setDomLayout('autoHeight');
        }
    };

    function onGridSizeChanged(containerId) {
        const container = document.getElementById(containerId);
        const heightRef = containerId.includes('trans') ? transGridHeightRef : adjustmentsGridHeightRef;
        heightRef.current = container?.clientHeight;
    };

    return (
        <Stack spacing={1} sx={{ p: 3, }}>
            <Accordion disableGutters >
                <StyledAccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    sx={{ minHeight: '24px', }}
                >
                    <Typography sx={{ p: 0.5 }}>Adjustments</Typography>
                </StyledAccordionSummary>
                <AccordionDetails sx={{ p: 0 }}>
                    <AgGridContainer id={adjustmentsGridContainerId}>
                        <AgGridReact
                            {...gridOptions}
                            rowData={adjustmentData}
                            columnDefs={adjustmentColDefs}
                            ref={adjustmentsGridRef}
                            onSelectionChanged={handleAdjustmentSelectionChanged}
                            rowSelection="single"
                            rowMultiSelectWithClick
                            sideBar={sideBar}
                            onGridSizeChanged={() => onGridSizeChanged(adjustmentsGridContainerId)}
                            onToolPanelVisibleChanged={() => onToolPanelVisibleChanged(adjustmentsGridContainerId)}
                        />
                    </AgGridContainer>
                </AccordionDetails>
            </Accordion>

            <Accordion disableGutters defaultExpanded >
                <StyledAccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    sx={{ minHeight: '24px' }}
                >
                    <Typography sx={{ p: 0.5 }}>Transmission Allocations</Typography>
                </StyledAccordionSummary>
                <AccordionDetails sx={{ p: 0 }}>
                    <AgGridContainer id={transGridContainerId}>
                        <AgGridReact
                            {...gridOptions}
                            rowData={transData}
                            columnDefs={transProfileColDefs}
                            ref={transGridRef}
                            onCellValueChanged={handleCellValueChanged}
                            sideBar={sideBar}
                            onGridSizeChanged={() => onGridSizeChanged(transGridContainerId)}
                            onToolPanelVisibleChanged={() => onToolPanelVisibleChanged(transGridContainerId)}
                        />
                    </AgGridContainer>
                </AccordionDetails>
            </Accordion>
        </Stack>
    );
};

const StyledAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
    [`& .MuiAccordionSummary-content`]: {
        margin: 0,
    },
}));