import { Ribbon } from "../../Ribbon";
import { TagActionsRibbonGroup } from "./TagActionsRibbonGroup";
import { TSRActionsRibbonGroup } from "./TSRActionsRibbonGroup";
import { GridActionsGroup } from "./GridActionsGroup";
import { ViewForm } from "./ViewForm";

export const TagReportRibbon = ({ toolbarFormId, selectedRows, gridRef, selectedAdjustment, handleRefresh, }) => {
    return (
        <Ribbon>
            {[
                {
                    title: 'Tags',
                    content: <TagActionsRibbonGroup
                        selectedTags={selectedRows}
                        gridRef={gridRef}
                        selectedAdjustment={selectedAdjustment}
                    />
                },
                {
                    title: 'TSRs',
                    visible: selectedRows[0]?.tagStatus?.toLowerCase() === 'draft',
                    content: <TSRActionsRibbonGroup
                        selectedTags={selectedRows}
                    />
                },
                {
                    title: 'Grid',
                    content: <GridActionsGroup
                        formId={toolbarFormId}
                        handleRefresh={handleRefresh}
                    />
                },
                {
                    title: 'Filter',
                    content: <ViewForm
                        onSubmit={handleRefresh}
                        formId={toolbarFormId}
                    />
                },
            ]}
        </Ribbon>
    );
}