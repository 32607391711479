import useHeader from "./useHeader"
import axios from "axios"
import { useSnackbar } from "notistack";
import { useActionAudit } from "./useActionAudit";
import { apiUrlPrefix } from "../authConfig";

export const useApi = (gridRef) => {
    const headers = useHeader();
    const { enqueueSnackbar } = useSnackbar();
    const { logAction } = useActionAudit();

    async function get(url) {
        return axios.get(url, { headers, }).catch(error => handleErrorResponse(error, url));
    }

    async function post(url, data, config = {}) {
        const cfg = { headers, ...config };
        return axios.post(url, data, cfg).catch(error => handleErrorResponse(error, url));
    }

    function handleErrorResponse(error, url) {
        let message = error.message;

        if (error.message === 'canceled') return; //User cancelled the request

        const errorData = error?.response?.data;
        if (typeof errorData === 'string' && errorData.includes('Crystal Ball API')) {
            const split = error.response.data.split('  ');
            message = message + ". " + split[0];
        }
        enqueueSnackbar(message, { variant: 'error' });
        logAction('User recieved backend error', encodeURIComponent(url), error);
        gridRef?.current?.api?.hideOverlay();
    }

    return {
        handleErrorResponse,
        get,
        post,
        headers,
        apiUrlPrefix,
        enqueueSnackbar,
        logAction,
    }
}