import PublishIcon from '@mui/icons-material/Publish';
import { Tooltip } from '@mui/material';
import { RibbonButton } from '../Ribbon';
import { useFormContext } from 'react-hook-form';
import { useDashboard } from '../useDashboard';

export const RepublishViewButton = ({ expanded, ...buttonProps }) => {
    const { getValues } = useFormContext();
    const { views, handleAddNewOrUpdate } = useDashboard();

    function handleRepublish() {
        const view = views.find(v => v.id === getValues('id')) ?? {};
        //iterate over keys of view and update them with the new values; we do this as we may not want to save all the values in the form
        let updatedView = { ...view };
        Object.keys(view).forEach(key => {
            const newVal = getValues(key);
            if (!!newVal) {
                updatedView[key] = getValues(key);
            }
        });
        handleAddNewOrUpdate(updatedView);
    }

    return (
        <Tooltip title="Save you current view along with the grid layout.">
            <span>
                <RibbonButton
                    onMouseDown={handleRepublish}
                    icon={<PublishIcon fontSize='small' />}
                    label={'Republish'}
                    expanded={expanded}
                    {...buttonProps}
                />
            </span>
        </Tooltip>
    );
}